import React from 'react'

const Terms_condition = () => {
  return (
    <div>
        <div className='container'>
            <h2 className='text-center mt-4'>TERMS AND CONDITIONS</h2>
            <div className='card'>
                <div className='card-body'>
               <h4> Terms & Conditions of use of the MYROCKETXPRESS.COM website</h4>
The following terms and conditions govern the use of the website www.Myrocketxpress.COM (“MYROCKETXPRESS.COM website”) of MYROCKETXPRESS.COM (“MYROCKETXPRESS.COM”), including all sections and services available on the MYROCKETXPRESS.COM website. The viewing or use of this site will constitute your agreement, on behalf of yourself and the entity you represent (hereinafter collectively "you" or "your"), to all of the terms and conditions provided below.
MYROCKETXPRESS.COM may make future changes or modifications to such terms and conditions at any time without notice, and any subsequent viewing or use of the MYROCKETXPRESS.COM website will constitute the user’s agreement to the changes and modifications. There may be additional terms and conditions provided throughout the MYROCKETXPRESS.COM website governing your use of particular functions, features, information and applications available through the MYROCKETXPRESS.COM website.<br/><br/>

<h4>Definitions:</h4>
“Content” means information, graphics, products, features, functionality, services, and links on the MYROCKETXPRESS.COM website, including ‘Tracking’, ‘Service Guide’, ‘Franchisee’, ‘Corporate Solutions’, ‘Careers’ and ‘eSolutions’.
“MYROCKETXPRESS.COM” means MYROCKETXPRESS.COM having its registered office at MYROCKETXPRESS.COM Manosa Tala Baroairy 76 Jaleshwar, Tili Para Street, santipur, West Bengal, India 741404, and any of its subsidiary and associate companies. “you” means yourself and/or the entity that you represent.<br/><br/>

<h4>Use of the MYROCKETXPRESS.COM website</h4>
The MYROCKETXPRESS.COM website is provided solely for the use of current and potential MYROCKETXPRESS.COM customers to interact with MYROCKETXPRESS.COM and may not be used by any other person or entity, or for any other purpose. Specifically, all information provided on the website including pertaining to shipping, tracking, rating, receiving invoices and remitting payment using electronic funds transfer, drop-off locations, identifying and preparing international documents, estimating duties and taxes, and other information and services may only be used by current and potential MYROCKETXPRESS.COM customers for their own shipments. Unless indicated otherwise in the terms of use of a specific interactive feature on the MYROCKETXPRESS.COM website, the use of the MYROCKETXPRESS.COM website to provide information to or prepare shipments by or for the benefit of third party shippers is expressly prohibited.<br/><br/>

<h4>MYROCKETXPRESS.COM Username Registration</h4>
You may choose to register on the MYROCKETXPRESS.COM website to access interactive features on the website. The MYROCKETXPRESS.COM website username provides you with access to various online services, including but not limited to ‘Tracking’, ‘Service Guide’, ‘Franchisee’ and ‘Corporate Solutions’. The availability of these services varies by country. In the future, MYROCKETXPRESS.COM may add other features that may be accessed through the MYROCKETXPRESS.COM website username. In such event, previously registered users may or may not be required to re-register for availing services offered by MYROCKETXPRESS.COM on the MYROCKETXPRESS.COM website. By registering on the MYROCKETXPRESS.COM website, you agree to provide accurate and current information about yourself as prompted by the MYROCKETXPRESS.COM website username registration pages and maintain and promptly update your online profile information to keep it accurate and current.
Some of the MYROCKETXPRESS.COM website services are being made available to customers based in some countries and not others. You agree to register only for the services offered on the MYROCKETXPRESS.COM website for the country in which your MYROCKETXPRESS.COM account is based. For example, if your MYROCKETXPRESS.COM account is based in India, you will register for services offered on the MYROCKETXPRESS.COM website specifically meant for India and not for services offered on any other website of MYROCKETXPRESS.COM or its subsidiaries/associate companies. When you register using the MYROCKETXPRESS.COM website username registration, you will select a user ID and password. You are responsible for maintaining the confidentiality of the password and user ID, and you are responsible for all activities that occur under your password and user ID. You agree to (a) immediately notify MYROCKETXPRESS.COM of any unauthorized use of your user ID and password, and (b) ensure that you exit/logout from your session on the MYROCKETXPRESS.COM website at the end of each visit.<br/><br/>

<h4>Changes to the MYROCKETXPRESS.COM website</h4>
The MYROCKETXPRESS.COM website and its Content, may be changed, deleted or updated at any time without notice.<br/><br/>

<h4>Termination of Use</h4>
MYROCKETXPRESS.COM reserves the right to discontinue, suspend or modify the Content of the MYROCKETXPRESS.COM website at any time without notice, and MYROCKETXPRESS.COM may block, terminate or suspend yours and any user's access to the MYROCKETXPRESS.COM website at any time for any reason in its sole discretion, even if access continues to be allowed to others.<br/><br/>

<h4>Ownership</h4>
The MYROCKETXPRESS.COM website and its Content are protected by the laws of the Republic of India and applicable international intellectual property and other laws. “MYROCKETXPRESS.COM” and its logo, a man running with a courier bag, is the registered trademark of MYROCKETXPRESS.COM in India. MYROCKETXPRESS.COM does not convey to anyone, through allowing access to the MYROCKETXPRESS.COM website, any ownership rights in the MYROCKETXPRESS.COM website or in any Content appearing on or made available through the MYROCKETXPRESS.COM website. Customer may not copy, modify, translate, transmit, distribute, adapt, reproduce, decompile, reverse engineer or disassemble any part of the MYROCKETXPRESS.COM website or it’s Content thereof.<br/><br/>

<h4>Disclaimer of Warranty</h4>
The MYROCKETXPRESS.COM website and its Content are provided "AS IS". MYROCKETXPRESS.COM and its licensors disclaim any and all warranties, express or implied, including without limitation, the implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, regarding any such Content and your ability or inability to use the MYROCKETXPRESS.COM website and its Content.
MYROCKETXPRESS.COM does not warrant that the Content of the MYROCKETXPRESS.COM website will meet all of customer's requirements or that its operations will be uninterrupted or error free, or that any defect within the MYROCKETXPRESS.COM website will be corrected. Furthermore, MYROCKETXPRESS.COM does not warrant nor make any representation regarding the results of customer's use of the MYROCKETXPRESS.COM website in terms of capability, correctness, accuracy, reliability or otherwise. No oral or written information, representation or advice given by MYROCKETXPRESS.COM or an authorized representative of MYROCKETXPRESS.COM shall create a warranty.<br/><br/>

<h4>Limitation of Liability</h4>
Use of the MYROCKETXPRESS.COM website and its Content is at your sole risk. The MYROCKETXPRESS.COM website will in no event be liable to you or any person or entity claiming through you for any direct, indirect, consequential, incidental or other damages under any theory of law for any errors in or the use of or inability to use the MYROCKETXPRESS.COM website and its content including without limitation, damage for lost profits, business, data, or damage to any computer systems, even if you have advised MYROCKETXPRESS.COM of the possibility of such damages.
Notwithstanding the above, to the extent permitted by applicable law, you agree that in no event shall MYROCKETXPRESS.COM's total liability for any damages (direct or otherwise) or loss regardless of the form of action or claim, whether in contract, tort or otherwise, exceed INR100/-(Rupees One Hundred only) for domestic consignments and US$100 (United States Dollar One Hundred only) for international consignments (outside India). To the extent permitted by law, the remedies stated for you in these terms and conditions are exclusive and are limited to those expressly provided for in these terms and conditions.<br/><br/>

<h4>Indemnity</h4>
You agree to defend, indemnify and hold harmless MYROCKETXPRESS.COM and its affiliate companies and their respective officers, directors, employees, agents and representatives from any and all claims arising out of your breach of any of these terms and conditions, and any of your activities conducted in connection with the MYROCKETXPRESS.COM website.<br/><br/>

<h4>MYROCKETXPRESS.COM Conditions Of Carriage</h4>
Packing and Labeling: It is the sender’s obligation to ensure adequate packing for the purpose of carriage with normal care in handling.<br/><br/>

<h4>Links to other web sites</h4>
There are links on the MYROCKETXPRESS.COM website that allows you to visit the sites of third parties. Neither these sites nor the companies to whom they belong are controlled by MYROCKETXPRESS.COM. MYROCKETXPRESS.COM makes no representations concerning the information provided or made available on such sites nor the quality or acceptability of the products or services offered by any persons or entities referenced in any such sites.
MYROCKETXPRESS.COM has not tested and makes no representations regarding the correctness, performance or quality of any software found at any such sites. You should research and assess the risks which may be involved in accessing and using any software on the Internet before using it.<br/><br/>

<h4>Controlling Law and Severability</h4>
This Agreement and your use of the MYROCKETXPRESS.COM website is governed by and construed in accordance with the laws of the Republic of India, excluding its conflict of laws provisions. If for any reason a court of competent jurisdiction finds any provision of this Agreement, or a portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible.
Any action with respect to the MYROCKETXPRESS.COM website or this Agreement must be instituted within one year after the claim or cause of action has risen and must be brought in a court of competent jurisdiction within the territory of the courts at Kolkata, West bengal, India. This Agreement may not be changed or modified without the written consent of MYROCKETXPRESS.COM.
If you have any doubt or query, please feel free to contact or write us at any time.
Email : support@Myrocketxpress.COM
Phone : +91 9339151330
                </div>
            </div>
        </div>
     
    </div>
  )
}

export default Terms_condition
