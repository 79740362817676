import React, { useState, createRef, useEffect } from "react";
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import config from "../../config"
import { useNetworkState } from 'react-use';
import ReactLoading from 'react-loading';
const Customer_register = () => {
  let navigate = useNavigate();
  const isOnline = useNetworkState();
  console.log("isONline  === " ,isOnline )
  let userDetail = localStorage.getItem('ship_rocket_user');
  
  // console.log("userDetail ===>   ",userDetail);
  const [state, setState] = useState({ email: "",password:"" });
  const [regsterState, setRegsterState] = useState({ email: "",password:"",full_name:"", company_name:""  , mobile_number:""});
  const [modalState, setModalState] = useState({ show: false });
  const [typeState, setTypeState] = useState({ type: "login" });
  const [userState, setUserState] = useState({ full_name: "" });
  const [styleState, setStyleState] = useState({ styles: 0 });
  const [trackState, setTrackState] = useState({tracking_no:""})
  const[trackresult,setTrackresult] = useState({})
  const[errorState,setErrorState] = useState()
  const[isLoading,setisLoading] = useState(false)

  

  
  
  useEffect(() => {

    if(userDetail!= null) {
      let uDetail = JSON.parse(userDetail)
      setUserState({full_name:uDetail.full_name})
      config.UserId = uDetail.customer_id
    }
  }, [])
  
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000
  })

  const inputHandleChange = (e) => {
    const value = e.target.value;
    const text = e.target.name;
    setState({ ...state, [text]: value })
  }
  const inputHandleChangeR = (e) => {
    const value = e.target.value;
    const text = e.target.name;
    setRegsterState({ ...regsterState, [text]: value })
  }
  const inputHandletracking = (e) => {
    const value = e.target.value;
    const text = e.target.name;
    setTrackState({ ...trackState, [text]: value })
  }

  const loginButton = (z) => {
   
    // console.log("state => ", state);
    if(state.email != "" && state.password != "") {
    let full_api = config.apiUrl + `/user/login_api`;
    let sendData = state;
    // console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
        // console.log("res ", res);
        if (res.data.isValid) {
          
            localStorage.setItem('ship_rocket_user', JSON.stringify(res.data.output));
            setUserState({full_name:res.data.output.full_name})
            config.UserId = res.data.output.customer_id
            
            setModalState({ show: false })
             window.location.reload(false);
           // navigate('/')
        }else {
          Toast.fire({
            background:"rgb(231, 91, 75)",
            type: 'unsuccess',
            title: "Email or password incorrct",
            color:"white"
          });
           
        }

    }).catch((e) => {


    });
  }else {
    Toast.fire({
      background:"rgb(231, 91, 75)",
      type: 'unsuccess',
      title: "Please fill both fields",
      color:"white"
    });
  }
}
const regitserButton = () =>{
  // console.log("regsterState", regsterState);
  if(regsterState.email != "" && regsterState.password != ""  && regsterState.full_name != "" && regsterState.company_name!="") {
    let full_api = config.apiUrl + `/user/register_api`;
    let sendData = regsterState;
    // console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
        // console.log("res ", res);
        if (res.data.isValid) {
          
            // localStorage.setItem('ship_rocket_user', JSON.stringify(res.data.output));
            // setUserState({full_name:res.data.output.full_name})
            // config.UserId = res.data.output.customer_id
            setModalState({ show: false })
            Toast.fire({
              background:"#206bc4",
              type: 'success',
              title: res.data.message ,
              color:"white"
            });
            let sendState = {email:sendData.email , mobile_number:sendData.mobile_number};
            // console.log("sendState === " , sendState)
          
             navigate('/customer/verify_email',{state:sendState})
            // navigate('/customer/dashboard')
        }else {
          Toast.fire({
            background:"#206bc4",
            type: 'unsuccess',
            title: res.data.message ,
            color:"white"
          });
           
        }

    }).catch((e) => {


    });
  }else {
    Toast.fire({
      background:"#206bc4",
      type: 'unsuccess',
      title: "Please fill all fields",
      color:"white"
    });
  }

}
const trackButton = () =>{
  setisLoading(true)
  

        setisLoading(false)
         navigate(`/tracking/`+ trackState.tracking_no)
  
}
const logOutButton = () =>{
  // console.log("userDetail ===>   ");
  localStorage.removeItem('ship_rocket_user');
  userDetail = null;
  setUserState({full_name:""})

}
const changestyle=()=>{
  setStyleState({styles:1})
}
const changeloginstyle=()=>{
  setStyleState({styles:0})
}
const openregister=()=>{
  setModalState({show:true})
  setStyleState({styles:1})
}
const openlogin=()=>{
  setModalState({show:true})
  setStyleState({styles:0})
}
  return (
//     <div>
//        <section class="login_box_sec">
//         <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
//             <div class="logo pb-5">
//                 <h2><img src="../../img/logo_5.png" class="img-fluid" /></h2>
//             </div>
//             <div class="card login_box border-0 shadow-sm py-4 rounded-5">
//                 <div class="card-body">
//                 <h4 class="border-start ps-3 mb-4 border-primary border-4">Register Your Account</h4>
//                     <form action=""  autocomplete="off">
//                     <div class="mb-3 mt-3">
//                           <label for="email" class="form-label">Company Name:</label>
//                           <input type="text" class="form-control" id="email" placeholder="Enter your company name" name="company_name" onChange={(e) => inputHandleChangeR(e)} requiredautocomplete="new-username" />
//                         </div>
//                         <div class="mb-3 mt-3">
//                           <label for="email" class="form-label">Full Name:</label>
//                           <input type="text" class="form-control" id="email" placeholder="Enter your full name" name="full_name" onChange={(e) => inputHandleChangeR(e)} requiredautocomplete="new-username" />
//                         </div>
//                         <div class="mb-3 mt-3">
//                           <label for="email" class="form-label">Email:</label>
//                           <input type="email" class="form-control" id="email" placeholder="Enter email" name="email" onChange={(e) => inputHandleChangeR(e)} required autocomplete="new-username" />
//                         </div>
//                         <div class="mb-3 mt-3">
//                           <label for="email" class="form-label">Mobile Number:</label>
//                           <input type="email" class="form-control" id="email" placeholder="Enter your mobile number" name="mobile_number" onChange={(e) => inputHandleChangeR(e)} required autocomplete="new-username" />
//                         </div>
                      
//                         <div class="mb-3">
//                           <label for="pwd" class="form-label">Create Password:</label>
//                           <input type="password" class="form-control" id="pwd" placeholder="Enter Confirm password" name="password" onChange={(e) => inputHandleChangeR(e)} required  autocomplete="new-password" />
//                         </div>
//                         <div class="text-center pt-3">
//                             <div class="d-grid">
//                               <a onClick={() =>
//    regitserButton()
//  } class="btn btn-primary btn-block">Register Now</a>
//                             </div>
//                             <div class="pt-4">
//                                 Existing user? <a href="/customer/login" className="text-decoration-none">Login Now</a>
//                             </div>
//                         </div>
//                       </form>
//                 </div>
//             </div>
//         </div>
//     </section>

    
//     </div>
<div>
<section class="mb-3 ">
      <div class="login_box_v2">
        <div class="row g-0">
          <div class="col-12 col-md-6 left">
            <img src="../img/logo_sky.png" class="img-fluid" />
            <h4>Register Your Account</h4>
            <p>Get register your account to access the customer panel.</p>
            <div class="row py-3">
                <div class="col-12 mb-3">
                    <label for="email" class="form-label">Full Name:</label>
                    <input type="text" class="form-control" id="email" placeholder="Enter your full name" name="full_name" onChange={(e) => inputHandleChangeR(e)}/>
                </div>
                <div class="col-12 mb-3">
                    <label for="email" class="form-label">Company Name:</label>
                    <input type="text" class="form-control" id="email" placeholder="Enter your full company name" name="company_name" onChange={(e) => inputHandleChangeR(e)}/>
                </div>
                <div class="col-12 mb-3">
                    <label for="email" class="form-label">Email:</label>
                    <input type="email" class="form-control" id="email" placeholder="Enter your email address" name="email" onChange={(e) => inputHandleChangeR(e)} />
                </div>
                <div class="col-12 mb-3">
                    <label for="email" class="form-label">Mobile:</label>
                    <input type="text" class="form-control" id="email" placeholder="Enter your mobile number" name="mobile_number" onChange={(e) => inputHandleChangeR(e)}/>
                </div>
                <div class="col-12 mb-3">
                    <label for="email" class="form-label">Create Password:</label>
                    <input type="password" class="form-control" id="email" placeholder="Enter your password" name="password" onChange={(e) => inputHandleChangeR(e)}/>
                </div>
                {/* <div class="col-12 mb-3">
                  <label for="email" class="form-label">Confirm Password:</label>
                    <input type="text" class="form-control" id="email" placeholder="Confirm your password" name="email"/>
                </div> */}
                <div class="col-12 mb-3">
                    <div class="d-grid">
                      <a onClick={() =>
    regitserButton()
  } class="btn btn-primary btn-block">Sign Up</a>
                    </div>
                </div>
            </div>

            <hr/>
            <p><a href="/customer/login">Return to login</a> </p>
            <div class="">
            <ul class="nav">
            <li class="nav-item">
        <a class="nav-link"  href="/terms&conditions">Terms & Condition</a>
    </li>
    <li class="nav-item">
        <a class="nav-link"  href="/privacy_policy">Privacy Policy</a>
    </li>
    {/* <li class="nav-item">
        <a class="nav-link"  href="/help">Help</a>
    </li> */}
    <li class="nav-item">
        <a class="nav-link" href="/contact">Contact</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" href="/refund">Refund</a>
    </li> <li class="nav-item">
        <a class="nav-link" href="/cancellation">Cancellation</a>
    </li>
</ul>
            </div>
            <div class="mt-3">
                <small>© 2023. All Rights Reserved.</small>
            </div>
          </div>
          <div class="col-12 col-md-6 right">
          <div id="login_panel" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                <button type="button" data-bs-target="#login_panel" data-bs-slide-to="0" class="active"></button>
                <button type="button" data-bs-target="#login_panel" data-bs-slide-to="1"></button>
                <button type="button" data-bs-target="#login_panel" data-bs-slide-to="2"></button>
                </div>
                <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="carousel-caption">
                        <h3>Effortless Deliveries, Every Time.</h3>
                        <p>Our delivery software ensures seamless operations, from order to doorstep. With real-time tracking and smart route optimization, your deliveries are always on time, hassle-free.</p>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-caption">
                    <h3>Deliver Beyond Boundaries.</h3>
                    <p>Break the barriers of distance with our advanced delivery software. Reach customers far and wide, with precision and speed. Experience the ease of global deliveries with just a click.</p>
                </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-caption">
                    <h3>Speed, Accuracy, Reliability - Delivered!</h3>
                    <p>Transform your delivery experience with our software engineered for precision. Say goodbye to errors and delays. Every package reaches its destination swiftly, accurately, and reliably, enhancing customer satisfaction.</p>
                </div>
                </div>
                </div>

                <button class="carousel-control-prev" type="button" data-bs-target="#login_panel" data-bs-slide="prev">
                <span class="carousel-control-prev-icon"></span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#login_panel" data-bs-slide="next">
                <span class="carousel-control-next-icon"></span>
                </button>
                </div>
          </div>
        </div>      
      </div>
    </section>
</div>
  )
}

export default Customer_register
