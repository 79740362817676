import React, { useState, createRef, useEffect } from "react";
import Left_menu from './Left_menu'
import Header from './Header'
import axios from "axios";
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import queryString from 'query-string';
import { useNetworkState } from 'react-use';
const Create_ticket = () => {
    let navigate = useNavigate()
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====shipments====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const[shipment,shipmentState]=React.useState({shipment_detail:[]})
const [state, setState] = React.useState({  subject:"" , ticket_createdById:"" , details:"",shipment_id:""   })
const [urlorder , seturlorder ] = React.useState({shipment_id:""})
const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector('.home-section');
    if (homeSection) {
        homeSection.classList.toggle('sidebar-closed');
    }
};
useEffect(()=>{
  const order_number = queryParams.get('order_number');
  if(order_number){
    setState({...state, shipment_id:order_number})
    seturlorder({...urlorder , shipment_id:order_number})
  }

},[location])

    const axios_get_api = (current_shipment_status) => {

        let full_api = config.apiUrl + `/home/support_shipments`;
        let sendData = {
          dstatus: 1,
          };
    
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          // console.log("-----res-----  ",res);
        
          shipmentState({ ...shipment, shipment_detail:res.data.output })
       
           
          // console.log("response", res);
        
    
        }).catch((e) => {
    
          // toast.configure()
          // toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }
      React.useEffect(() => {
        axios_get_api()
      }, [])


     
      const handleSupport = () => {
    
        // console.log("submit1");
       
        let state_detailes = { subject:state.subject , details:state.details,shipment_id:state.shipment_id }
      
      
        // console.log("report detailes is ",state_detailes);
        if(state.subject!="" && state.details!=""){
        axios.post(config.apiUrl + `/home/add_support`,  state_detailes , { headers: config.headers }).then((result)=>{
                // console.log("result is i ",result);
               
                if(result && result.data != undefined){
                   
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000
                      })
                      
                      Toast.fire({
                        background:"#206bc4",
                        type: 'success',
                        title: result.data.message,
                        color:"white"
                      });
                        navigate("/customer/tickets")
                        
                    }
     
             }).catch((e) => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"#206bc4",
                    type: 'unsuccess',
                    title: 'Something went wrong',
                    color:"white"
                  });
     
            //   toast.configure();
            //   toast.error("Some thing went wrong");
              // console.log("----error:   ", e);
            });}
            else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"#206bc4",
                    type: 'unsuccess',
                    title: 'Please Fill the Mandatory Fields ',
                    color:"white"
                  });
         
            }
    }
    
    const handleChange = (event) => {
      // console.log(event);  
      setState({
          ...state,
          [event.target.name]: event.target.value,
        });
      
    }
    
    const handleChange1 =(event)=>{
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.delete('order_number'); // Remove the 'customer_id' parameter
      currentUrl.searchParams.set('order_number', event.target.value); // Set the new 'customer_id' parameter
      
      // Update the URL
      window.history.replaceState({}, '', currentUrl.toString());
      setState({
        ...state,
        [event.target.name]: event.target.value,
      });

    }
      

  return (
    <div>
<Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
         
          <Header/>
<section class="bg-light p-5">
  <div class=" ">

    <section class="bg-white p-5 mb-2">
      <div className='row'>
        <div className='col-6'>
        <div class="text-muted">
         <p><a href="/customer/tickets" className="text-decoration-none"><i class="fa fa-angle-left" aria-hidden="true" ></i> Back</a></p>
        
      </div>
        </div>
        <div className='col-6'>
        <p className='text-end'><a href="/customer/tickets" className="text-decoration-none"> View All Tickets</a></p>
        </div>

      </div>
     
      <div class="row pt-1">
        <div class="col-md-12">
       
            <section class="">
              <div class="mb-3 mt-3">
                <label for="email" class="form-label">Subject<small className='text-danger'>*</small></label>
                <input type="text" class="form-control" id="email" placeholder="Please enter the subject" name="subject" value={state.subject} onChange={(e) => handleChange(e)}/>
              </div>
              <div class="mb-3 mt-3">
              <label for="email" class="form-label">Shipment</label>
              {/* <select class="form-select" name="shipment_id"  onChange={(e) => handleChange1(e)}>
                                      
                                    <option value="">{urlorder.shipment_id!=""?urlorder.shipment_id:"Select"}</option>
                                   
                                      { shipment.shipment_detail.map((subscrib,index) => {
                                         return(
                                        <option value={subscrib.carrier_tracking_number}>{subscrib.carrier_tracking_number}</option>
                                         )
                                      })}
                                    </select> */}
                                    <input className="form-control" name="shipment_id" value={state.shipment_id} onChange={(e) => handleChange1(e)}></input>
               </div>                     
              <div class="mb-3 mt-3">
                <label for="comment">Details<small className='text-danger'>*</small></label>
                <textarea class="form-control" rows="5" id="comment" name="details" placeholder="Please enter details" value={state.details} onChange={(e) => handleChange(e)}></textarea>
              </div>

              
              <a  class="btn btn-primary mt-3" onClick={()=>handleSupport()}>Submit</a>
            </section>
     </div>
        
      </div>
    </section>

    
  </div>
</section>
</div>
    </div>
    
  )
}

export default Create_ticket
