import React, { useState, createRef, useEffect } from "react";
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import config from "../../config"
import { useNetworkState } from 'react-use';

const Customer_Login = () => {
  let navigate = useNavigate();
  let userDetail = localStorage.getItem('ship_rocket_user');
  const isOnline = useNetworkState();
  console.log("isONline  === " ,isOnline )
  // console.log("userDetail ===>   ",userDetail);
  const [state, setState] = useState({ email: "",password:"" });
  const [regsterState, setRegsterState] = useState({ email: "",password:"",full_name:"" });
  const [modalState, setModalState] = useState({ show: false });
  
  const [userState, setUserState] = useState({ full_name: "" });
  const [styleState, setStyleState] = useState({ styles: 0 });
  const [trackState, setTrackState] = useState({tracking_no:""})
 
  const[isLoading,setisLoading] = useState(false)

  

  
  
  useEffect(() => {

    if(userDetail!= null) {
      let uDetail = JSON.parse(userDetail)
      setUserState({full_name:uDetail.full_name})
      config.UserId = uDetail.customer_id
    }
  }, [])
  
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000
  })

  const inputHandleChange = (e) => {
    const value = e.target.value;
    const text = e.target.name;
    setState({ ...state, [text]: value })
  }
 

  const loginButton = (z) => {
   
    // console.log("state => ", state);
    if(state.email != "" && state.password != "") {
    let full_api = config.apiUrl + `/user/login_api`;
    let sendData = state;
    // let headers = config.headers
    // console.log("bb", sendData);
    axios.post(full_api, sendData, { headers:config.headers }).then((res) => {
        // console.log("res ", res);
        if (res.data.isValid) {
            localStorage.setItem('ship_rocket_user', JSON.stringify(res.data.output));
            setUserState({full_name:res.data.output.full_name})
            config.UserId = res.data.output.customer_id
            setModalState({ show: false })
            navigate('/customer/dashboard')
        }else {
          Toast.fire({
            background:"rgb(231, 91, 75)",
            type: 'unsuccess',
            title: res.data.message,
            color:"white"
          });
           
        }

    }).catch((e) => {


    });
  }else {
    Toast.fire({
      background:"rgb(231, 91, 75)",
      type: 'unsuccess',
      title: "Please fill both fields",
      color:"white"
    });
  }
}





  return (
    // <div>
    //    <section class="login_box_sec">
    //     <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
    //         <div class="logo pb-5">
    //             <h2><img src="../../img/logo_5.png" class="img-fluid" /></h2>
    //         </div>
    //         <div class="card login_box border-0 shadow-sm py-4 rounded-5">
    //             <div class="card-body">
    //                 <form action=""  autocomplete="off">
    //                     <div class="mb-3 mt-3">
    //                       <label for="email" class="form-label">Email:</label>
    //                       <input type="email" class="form-control" id="email" placeholder="Enter email"  name="email" onChange={(e) => inputHandleChange(e)} autocomplete="new-username"  />
    //                     </div>
    //                     <div class="mb-3">
    //                       <label for="pwd" class="form-label">Password:</label>
    //                       <input type="password" class="form-control" id="pwd" placeholder="Enter password" name="password" onChange={(e) => inputHandleChange(e)}  autocomplete="new-password" />
    //                     </div>
    //                     {/* <div class="d-flex justify-content-between">
    //                         <div class="">
    //                             <div class="form-check mb-3">
    //                                 <label class="form-check-label">
    //                                   <input class="form-check-input" type="checkbox" name="remember" /> Remember me
    //                                 </label>
    //                             </div>
    //                         </div>
    //                         <div class="">
    //                             <a href="forgot.php">Forgot Password?</a>
    //                         </div>
    //                     </div> */}
    //                     <div class="text-center pt-3">
    //                         <div class="d-grid">
    //                           <a onClick={() =>
    //                    loginButton()} class="btn btn-primary btn-block">Login</a>
    //                         </div>
    //                         <div class="pt-4">
    //                             New user? <a href="/customer/registration" className="text-decoration-none">Sign Up Now</a>
    //                         </div>
    //                     </div>
    //                   </form>
    //             </div>
    //         </div>
    //     </div>
    // </section>
    // </div>
    <div>
       <section class="mb-3 ">
      <div class="login_box_v2">
        <div class="row g-0">
          <div class="col-12 col-md-6 left">
            <img src="../../img/logo_3.png" class="img-fluid" />
            <h4>Sign-In</h4>
            <p>Access the customer panel using your email and password.</p>
            <div class="row py-3">
                <div class="col-12 mb-3">
                    <label for="email" class="form-label">Email:</label>
                    <input type="email" class="form-control" id="email" placeholder="Enter your email address" name="email" onChange={(e) => inputHandleChange(e)} autocomplete="new-username"/>
                </div>
                <div class="col-12 mb-3">
                    <div class="d-flex justify-content-between">
                        <div class="">
                            <label for="email" class="form-label">Password:</label>
                        </div>
                        <div class="">
                            {/* <small><a href="forgot.php">Forgot Password?</a></small> */}
                        </div>
                    </div>
                    <input type="password" class="form-control" id="email" placeholder="Enter your password" name="password" onChange={(e) => inputHandleChange(e)}  autocomplete="new-password" />
                </div>
                <div class="col-12 mb-3">
                    <div class="d-grid">
                      <a onClick={() =>loginButton()} class="btn btn-primary btn-block">Sign In</a>
                    </div>
                </div>
            </div>

            <hr/>
            <p>New on our platform? <a href="/customer/registration">Create an account</a> </p>
            <div class="">
            <ul class="nav">
            <li class="nav-item">
        <a class="nav-link"  href="/terms&conditions">Terms & Condition</a>
    </li>
    <li class="nav-item">
        <a class="nav-link"  href="/privacy_policy">Privacy Policy</a>
    </li>
    {/* <li class="nav-item">
        <a class="nav-link"  href="/help">Help</a>
    </li> */}
    <li class="nav-item">
        <a class="nav-link" href="/contact">Contact</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" href="/refund">Refund</a>
    </li> <li class="nav-item">
        <a class="nav-link" href="/cancellation">Cancellation</a>
    </li>
</ul>
            </div>
            <div class="mt-3">
                <small>© 2023. All Rights Reserved.</small>
            </div>
          </div>
          <div class="col-12 col-md-6 right">
          <div id="login_panel" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                <button type="button" data-bs-target="#login_panel" data-bs-slide-to="0" class="active"></button>
                <button type="button" data-bs-target="#login_panel" data-bs-slide-to="1"></button>
                <button type="button" data-bs-target="#login_panel" data-bs-slide-to="2"></button>
                </div>
                <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="carousel-caption">
                        <h3>Effortless Deliveries, Every Time.</h3>
                        <p>Our delivery software ensures seamless operations, from order to doorstep. With real-time tracking and smart route optimization, your deliveries are always on time, hassle-free.</p>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-caption">
                    <h3>Deliver Beyond Boundaries.</h3>
                    <p>Break the barriers of distance with our advanced delivery software. Reach customers far and wide, with precision and speed. Experience the ease of global deliveries with just a click.</p>
                </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-caption">
                    <h3>Speed, Accuracy, Reliability - Delivered!</h3>
                    <p>Transform your delivery experience with our software engineered for precision. Say goodbye to errors and delays. Every package reaches its destination swiftly, accurately, and reliably, enhancing customer satisfaction.</p>
                </div>
                </div>
                </div>

                <button class="carousel-control-prev" type="button" data-bs-target="#login_panel" data-bs-slide="prev">
                <span class="carousel-control-prev-icon"></span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#login_panel" data-bs-slide="next">
                <span class="carousel-control-next-icon"></span>
                </button>
                </div>
          </div>
        </div>      
      </div>
    </section>
    </div>
  )
}

export default Customer_Login
