import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import Billing_nav from './Billing_nav'
import {  useNavigate } from 'react-router-dom'
// import Customer_header from './Customer_header'
import axios from "axios";
import Swal from 'sweetalert2';

import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import config from "../../config"
import { useNetworkState } from 'react-use';

const Billing_cod_remittance = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    let navigate = useNavigate()
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const[state,setState] = React.useState({wallet_balance:""})
    const[balancestate, setbalanceState ]= React.useState({add_balance:[], total_balance:[]})
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const[transaction  , settransaction] = React.useState([])
    const[codremitted , setcodremitted] = React.useState({last_cod_remitted:0 , next_cod_remitted:0 , remittance_due:0})

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
   React.useEffect(()=>{
    get_cod_wallet_balance()
    dimension_detail()
    cod_wallet_transactions()
   },[])

    const get_cod_wallet_balance=()=>{
     let dataToSend = {customer_id : userData.customer_id};
    
      // console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/user/get_cod_wallet_balance';
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
            if(res.data.status==true){
        setbalanceState({add_balance:res.data.added_wallet_transactions, total_balance:res.data.find_customer_wallet})
     }
    else{
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          Toast.fire({
            background:"rgb(231, 91, 75)",
            type: 'Unsuccess',
            title: "Something Went Wrong !",
            color:"white"
          });
    } })
        
          .catch((error) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'Unsuccess',
                title: "Something Went Wrong !",
                color:"white"
              });
              // console.log(error);
          });    
    
    }

   
      
    const dimension_detail = () =>{
        let full_api = config.apiUrl + `/user/get_company_info_api`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
          // console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
      }

      const cod_wallet_transactions=()=>{
        let dataToSend = {customer_id : userData.customer_id};
       
         // console.log("datatoSend",dataToSend)
         let url = config.apiUrl + '/shipment/get_cod_wallet_transactions';
         axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
               // console.log("responseJson => ", res);
               if(res.data.status==true){
                settransaction(res.data.output)
                setcodremitted({last_cod_remitted: res.data.last_cod_remitted, next_cod_remitted:res.data.next_cod_remitted , remittance_due:res.data.due_remittance})
        }
       else{
           const Toast = Swal.mixin({
               toast: true,
               position: 'bottom-end',
               showConfirmButton: false,
               timer: 3000
             })
             Toast.fire({
               background:"rgb(231, 91, 75)",
               type: 'Unsuccess',
               title: "Something Went Wrong !",
               color:"white"
             });
       } })
           
             .catch((error) => {
               const Toast = Swal.mixin({
                   toast: true,
                   position: 'bottom-end',
                   showConfirmButton: false,
                   timer: 3000
                 })
                 Toast.fire({
                   background:"rgb(231, 91, 75)",
                   type: 'Unsuccess',
                   title: "Something Went Wrong !",
                   color:"white"
                 });
                 // console.log(error);
             });    
       
       }
     
  return (
    <div>
          {/* <?php include 'meta.php';?> */}
    
    {/* <?php include 'left_menu.php';?> */}
    <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
           
            <Header/>
        

        {/* <?php include 'billing_nav.php';?>    */}
<Billing_nav value={8}/>
        <section class="mb-3">
          <div class="">
            <div class="card border-0">
              <div class="card-body">
                <div class="row states stboxbil py-3">
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded  ">
                        {balancestate.total_balance.map((sub)=>( <h5>&#8377;{parseFloat(sub.cod_wallet_balance).toFixed(2)}</h5>))}
                            <small>COD Wallet <a href="#" data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover" data-bs-content="Amount to be remitted in next cycle" class="text-muted"><i class="fa fa-question-circle-o" aria-hidden="true"></i></a></small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                       { codremitted.last_cod_remitted!=0 ?  <h5>&#8377;{codremitted.last_cod_remitted.toFixed(2)}</h5>:<h5>----</h5>}
                            <small>Last COD Remitted</small>
                        </div>
                    </div>
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                        {codremitted.next_cod_remitted!=0?  <h5>&#8377;{codremitted.next_cod_remitted.toFixed(2)}</h5>:<h5>----</h5>}
                            <small>Next COD Remittance</small>
                        </div>
                    </div>
                    {/* <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;0.00</h5>
                            <small>Total deduction from COD</small>
                        </div>
                    </div> */}
                    <div class="col-12 col-md-2 mb-3">
                        <div class="stbox text-center py-4 border rounded ">
                            <h5>&#8377;{codremitted.remittance_due.toFixed(2)}</h5>
                            <small>Remittance Due</small>
                        </div>
                    </div>
                </div>
              </div>
            </div>     
          </div>
        </section>

        <section class="mb-3">
          <div class="container">
            <div>
                {/* <div class="input-group mb-3 input-group-sm">
                    <input type="date" class="form-control" placeholder="Search by Order ID"/>
                    <select class="form-select">
                      <option>Last one year</option>
                      <option>2022</option>
                      <option>2021</option>
                      <option>2020</option>
                    </select>
                    <input type="text" class="form-control w-25" placeholder="Search by Order ID"/>
                    <button class="btn btn-primary" type="submit">Search</button>
                </div> */}
            </div>
            <div class=" table-responsive">
                <table class="table table-bordered table_box billing_table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Transaction Id</th>
                        <th>Shipment Id</th>
                        <th>Method</th>
                        <th>Amount</th>
                        <th>Status</th>
                         </tr>
                    </thead>
                    <tbody>
                      {transaction.map((sub)=>(
                      <tr>
                      <td>{sub.date?sub.date:<Moment format='DD-MM-YYYY'>{new Date(sub.created*1000)}</Moment>}</td>
                      <td>{sub.transaction_id}</td>
                      <td>{sub.shipment_id}</td>
                      <td>{sub.payment_method}</td>

                      <td>{sub.amount}</td>
                      <td>{sub.status==2?<span class="badge rounded-pill bg-success fw-400">Success</span>:<span class="badge rounded-pill bg-success fw-400">Success</span>}</td>

                      </tr>
                      ))}
                     
                      {/* <tr>
                        <td colspan="12" class="text-center">
                          <p>Your remittance is on its way</p>
                          <small>We release COD remittance 3 times in a week and on the 8th day from the date of delivery.</small>
                        </td>
                      </tr> */}
                    </tbody>
                </table>  
            </div>
          </div>
        </section>
        
        
    

    </div>
    {/* <?php include 'footer.php';?> */}
    </div>
  )
}

export default Billing_cod_remittance
