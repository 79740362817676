import React, { useState, createRef, useEffect } from "react";
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import config from "../../config"

import { useLocation } from 'react-router-dom';
import { useNetworkState } from 'react-use';

const Verify_email = () => {
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    let navigate = useNavigate()
    const location = useLocation();
    // console.log("location",location);
    //location.state.email
    const [userState, setUserState] = useState({ full_name: "" });
    const [state, setState] = React.useState({ email: location.state.email,otp:"" , mobile_number:location.state.mobile_number })
    const initialTimeInSeconds = 120; // 2 minutes
    const [timeLeft, setTimeLeft] = useState(initialTimeInSeconds);
    const [timerActive, setTimerActive] = useState(true); // State to track whether the timer is active
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
    });

    useEffect(() => {
        let timerInterval;

        if (timerActive) {
            timerInterval = setInterval(() => {
                if (timeLeft > 0) {
                    setTimeLeft((prevTime) => prevTime - 1);
                } else {
                    setTimerActive(false); // Timer has reached 0, so deactivate it
                }
            }, 1000);
        }

        return () => {
            clearInterval(timerInterval);
        };
    }, [timeLeft, timerActive]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
    const handleResendClick = () => {
        // Reset the timer and make it active again
        setTimeLeft(initialTimeInSeconds);
        setTimerActive(true);
        let full_api = config.apiUrl + `/user/otp_resend`;
        // let fullOtp = state.otp1+""+state.otp2+""+state.otp3+""+state.otp4+""+state.otp5+""+state.otp6
         let sendData = { email: state.email, otp: state.otp };
         // console.log(sendData);
         axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
           // console.log(res);
           if (res.data.status == true) {
            Toast.fire({
                background:"#206bc4",
                type: 'success',
                title: res.data.message ,
                color:"white"
              });
              let sendState = { mobile_number:sendData.mobile_number};
              // console.log("sendState === " , sendState)
            
            //    navigate('/customer/verify_sms',{state:sendState})
           }else {
            Toast.fire({
                background:"#206bc4",
                type: 'success',
                title: res.data.message ,
                color:"white"
              });
           }
           
         }).catch((e) => {
           //setState({...state,isLoading: false});
 
           // toast.configure();
           // toast.error("Some thing went wrong");
           // // console.log("----error:   ", e);
         });
    };
    
    const handleChangeBroker = (event) => {
        setState({
                 ...state,
                 [event.target.name]: event.target.value,
               });
  
      }
  
      const axios_registration_api = (event) => {
        event.preventDefault();
        if (state.email != '') {
          let full_api = config.apiUrl + `/user/otp_verification`;
         // let fullOtp = state.otp1+""+state.otp2+""+state.otp3+""+state.otp4+""+state.otp5+""+state.otp6
          let sendData = { email: state.email, otp: state.otp , mobile_number:state.mobile_number };
        
          // console.log(sendData);
          axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
            // console.log("response11",res);
        //    if(res.data.status==true){
        //     Toast.fire({
        //         background:"#206bc4",
        //         type: 'success',
        //         title: res.data.message ,
        //         color:"white"
        //       });
        //       let sendState = { mobile_number:sendData.mobile_number};
        //       // console.log("sendState === " , sendState)
            
        //        navigate('/customer/verify_sms',{state:sendState})
        //    }
        //    else{
        //     Toast.fire({
        //         background:"#206bc4",
        //         type: 'success',
        //         title: res.data.message ,
        //         color:"white"
        //       });
        //    }
        if(res.data.status==true){
            localStorage.setItem('ship_rocket_user', JSON.stringify(res.data.output));
            setUserState({full_name:res.data.output.full_name})
            config.UserId = res.data.output.customer_id
           Toast.fire({
               background:"#206bc4",
               type: 'success',
               title: res.data.message ,
               color:"white"
             });
             setTimeout(()=>{
               navigate('/customer/dashboard')
             },2000)
           }
           else{
            Toast.fire({
                background:"#206bc4",
                type: 'success',
                title: res.data.message ,
                color:"white"
              });
           }
            
          }).catch((e) => {
           
          });
        }
      };
     
  return (
//     <div>
   
// <section class="verify_email_box_sec">
//     <div class="d-flex flex-column min-vh-100 justify-content-center align-items-center">
//         <div class="logo pb-5">
//             <h2><img src="../../img/logo_5.png" class="img-fluid" /></h2>
//         </div>
//         <div class="card login_box border-0 shadow-sm py-4 px-5 rounded-5">
//             <div class="card-body">
//                 <div class="alert alert-info mb-4" role="alert">
//                     We have sent you a six-digit OTP on your email <strong>{state.email}</strong>. Please check your inbox or spam folder and enter it below.
//                 </div>
             
//                     <div class="mb-3 d-flex justify-content-center">
//                     <input type="text" maxLength="6" className="form-control  " id="" placeholder="XXXXXX" required  style={{width:90}} name="otp" onChange={(e)=>handleChangeBroker(e)}/>
                     
//                     </div>
//                     <div class="text-center pt-3">
//                         <div class="d-grid">
//                             <a onClick={(e)=>axios_registration_api(e)} class="btn btn-primary btn-block">Verify Email</a>
//                         </div>
//                         <div class="pt-4">
//                         Didn't receive OTP? {timerActive ? (
//                                         <span id="resendTimer">Resend OTP in {formatTime(timeLeft)} </span>
//                                     ) : (
//                                         <button onClick={handleResendClick} className="btn btn-primary">Resend OTP</button>
//                                     )}
//                         </div>
//                     </div>
             
//             </div>
//         </div>
//     </div>
// </section>

//     </div>
<div>
<section class="mb-3 ">
      <div class="login_box_v2">
        <div class="row g-0">
          <div class="col-12 col-md-6 left">
            <img src="../img/logo_sky.png" class="img-fluid" />
            <h4>Verify Email address</h4>
            <p>We have sent you a six-digit OTP on your email <strong>{state.email}</strong>. Please check your inbox or spam folder and enter it below.</p>
            <div class="row py-3">
                <div class="col-12 mb-3">
                    <div class="d-flex justify-content-between">
                        <div class="">
                            <label for="email" class="form-label">Enter OTP:</label>
                        </div>
                        <div class="">
                            <small> Didn't receive OTP? {timerActive ? (
                                         <span id="resendTimer">Resend OTP in {formatTime(timeLeft)} </span>
                                     ) : (
                                         <button onClick={handleResendClick} className="btn btn-primary">Resend OTP</button>
                                     )}</small>
                        </div>
                    </div>
                    <input type="text" class="form-control" id="email" placeholder="Enter OTP" name="otp" onChange={(e)=>handleChangeBroker(e)} />
                </div>
                <div class="col-12 mb-3">
                    <div class="d-grid">
                      <a onClick={(e)=>axios_registration_api(e)} class="btn btn-primary btn-block">Verify Email</a>
                    </div>
                </div>
            </div>

            <hr/>
            <p><a href="login.php">Return to login</a> </p>
            <div class="">
            <ul class="nav">
            <li class="nav-item">
        <a class="nav-link"  href="/terms&conditions">Terms & Condition</a>
    </li>
    <li class="nav-item">
        <a class="nav-link"  href="/privacy_policy">Privacy Policy</a>
    </li>
    {/* <li class="nav-item">
        <a class="nav-link"  href="/help">Help</a>
    </li> */}
    <li class="nav-item">
        <a class="nav-link" href="/contact">Contact</a>
        
    </li>
    <li class="nav-item">
        <a class="nav-link" href="/refund">Refund</a>
    </li> <li class="nav-item">
        <a class="nav-link" href="/cancellation">Cancellation</a>
    </li>
</ul>
            </div>
            <div class="mt-3">
                <small>© 2023. All Rights Reserved.</small>
            </div>
          </div>
          <div class="col-12 col-md-6 right">
          <div id="login_panel" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-indicators">
                <button type="button" data-bs-target="#login_panel" data-bs-slide-to="0" class="active"></button>
                <button type="button" data-bs-target="#login_panel" data-bs-slide-to="1"></button>
                <button type="button" data-bs-target="#login_panel" data-bs-slide-to="2"></button>
                </div>
                <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="carousel-caption">
                        <h3>Effortless Deliveries, Every Time.</h3>
                        <p>Our delivery software ensures seamless operations, from order to doorstep. With real-time tracking and smart route optimization, your deliveries are always on time, hassle-free.</p>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-caption">
                    <h3>Deliver Beyond Boundaries.</h3>
                    <p>Break the barriers of distance with our advanced delivery software. Reach customers far and wide, with precision and speed. Experience the ease of global deliveries with just a click.</p>
                </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-caption">
                    <h3>Speed, Accuracy, Reliability - Delivered!</h3>
                    <p>Transform your delivery experience with our software engineered for precision. Say goodbye to errors and delays. Every package reaches its destination swiftly, accurately, and reliably, enhancing customer satisfaction.</p>
                </div>
                </div>
                </div>

                <button class="carousel-control-prev" type="button" data-bs-target="#login_panel" data-bs-slide="prev">
                <span class="carousel-control-prev-icon"></span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#login_panel" data-bs-slide="next">
                <span class="carousel-control-next-icon"></span>
                </button>
                </div>
          </div>
        </div>      
      </div>
    </section>
</div>
  )
}

export default Verify_email
