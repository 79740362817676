import React, { useEffect, useState } from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import Create_order_nav from './Create_order_nav'
import axios from 'axios'
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
//import cashfree from '../../cashfreeUtil';
import Moment from 'react-moment';

import Swal from 'sweetalert2'
import TablePagination from '@mui/material/TablePagination';

import ReactLoading from 'react-loading';
import config from "../../config"

import { useNetworkState } from 'react-use';


const Weight_mismatch = () => {
    let navigate = useNavigate()
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
 
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====shipments====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }

    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
 
    const[loaderpickup , setloaderpickup] = React.useState(false)
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
    const[state,setState]= React.useState({shipment_list:[],isLoading:true})
    const[labelstate,setlabel]= React.useState({shipment_list:[],isLoading:true})
    const[searchstate,setsearchstate]= React.useState({search_shipment:""})

    const [shipmentstate, setshipStatus] = React.useState({ shipment_status: null });
    const [shipSelect, setShipSelect] = React.useState({ shipment_id: "",cancel_reason:"" , total_amount:0 });
    const [modalState, setModalState] = React.useState({ show: false });
    const [modalpickupState, setModalpickupState] = React.useState({ show: false });
    const[isLoading , setisLoading] =React.useState(true)
    const[pickupstate , setpickupstate] = React.useState({  warehouse_name:""});
    // console.log("pickupstate ==== " , pickupstate)
    const [selectedShipments, setSelectedShipments] = React.useState([]);
    // console.log("selectedShipments === " ,selectedShipments)
    const [selectCount ,setSelectCount] = React.useState({select_count:0 })
    const[addressState, setaddressState] = React.useState([]);
    const[userstate , setUserState] = React.useState({})
    const [otherStates,setOtherState] = React.useState({dstatus:"",activePage:1,rowsPerPage:20,page:0,total_count:0,onload:true});
    // console.log("otherState ==== " , otherStates)
    const[isloading , setisloading] = React.useState(true)

    const [selectedDateTime, setSelectedDateTime] = React.useState(null);
    const [selectedTime, setSelectedTime] = React.useState(null);
    const[cancelshipment , setcancelshipment] = React.useState(false)
    const[date, setdate] = React.useState({date:""})
    const[paymentmode, setpaymentmode] = React.useState({cod:false,prepaid:false})

  

    const shipment_list=(status,index=0,onLoad)=>{
        if(isOnline.online==true){
          let dataToSend = {customer_id : userData.customer_id ,find:searchstate.search_shipment,  status:status , limit:otherStates.rowsPerPage,indexValue:index
      };
      if(date.date!=""){
        dataToSend.date = date.date
      }
     
      if (paymentmode.cod && !paymentmode.prepaid) {
        dataToSend.payment_mode = 'cod';
    } else if (!paymentmode.cod && paymentmode.prepaid) {
        dataToSend.payment_mode = 'prepaid';
    } else if (paymentmode.cod && paymentmode.prepaid) {
        dataToSend.payment_mode = 'both';
    }
     
        // console.log("datatoSend",dataToSend)
        let url = config.apiUrl + '/shipment/weight_discrepancies_list';
       
       // console.log("headers =========> ", config.headers);
       
        
        axios.post(url, dataToSend, { headers: config.headers })
        // // console.log("headers ====== " , config.headers)
            .then((res) => {
              console.log("responseJson => ", res);
              if( onLoad) {
                setOtherState({...otherStates,total_count:res.data.count , page:index})         
  
    }
              setState({shipment_list:res.data.output,isLoading:false})
              setisLoading(false)
           
            })
            .catch((error) => {
                //Hide Loader
              //   setLoadingstate({...loadingstate,loading:false})
                // console.log(error);
            });    
          }
          else{
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: "Network Connection Error",
              
            })
          }
  
      }
      React.useState(()=>{
        shipment_list(null,0,true)
    },[])

    const handlesearchShipment=(e)=>{
        setsearchstate({search_shipment:e.target.value})
       }
    
       const dateselect =(e)=>{
        setdate({date:e.target.value})
     }

     React.useEffect(()=>{
        shipment_list(null,0,true)
      },[date.date ])

      React.useEffect(()=>{
        shipment_list(null,0,true)
      },[otherStates.rowsPerPage])
      const handlePageChange = (event,newPage) =>{
        setOtherState({...otherStates,page:newPage})
        // console.log("newPage ", newPage);
        // getCountryList(newPage);
        shipment_list(shipmentstate.shipment_status,newPage,true)
    }
    const handleChangeRowsPerPage = (event , newPage) => {
        // console.log("event ", event.target.value);
        setOtherState({ ...otherStates, rowsPerPage: event.target.value })
         
    }
  return (
    <div>
         <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
         
          <Header/>
    <section class="mb-3">
      <div class="container-fluid">
        <div class="row mb-3">
            <div class="col-12 col-md-4">
                <div class="d-flex">
                  {/* <a href="#" class="btn-back">
                    <i class="bx bx-arrow-back"></i>
                  </a> */}
                  <h4>Weight Discrepancies</h4>
                </div>
            </div>
            <div class="col-12 col-md text-end">
              
            </div>
        </div>
        <div class="col-12 col-md">
                    <div class="row">
                        <div class="col-12 col-md-5">
                            <div class="input-group input-group-sm mb-3">
                                {/* <select class="form-select">
                                    <option>Order ID</option>
                                    <option>Phone Number</option>
                                </select> */}
                                <input type="text" class="form-control" name="search_shipment" placeholder="Enter AWB Number" onChange={(e)=>handlesearchShipment(e)} value={searchstate.search_shipment}/>
                 <button class="btn btn-primary" onClick={(e)=>shipment_list(null,0,true)}>Search</button>
                            </div>
                        </div>
                        <div class="col-12 col-md-3">
                            <input type="date" class="form-control form-control-sm" id="text" placeholder="Enter details name=" name="date" onChange={(e)=>dateselect(e)}/>
                        </div>
                      
                     
                    </div>
                </div>

        <div class="card mb-3 border-0 shadow-sm ">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table ">
                  <thead class="table-light">
                    <tr>
                      {/* <th><input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/></th> */}
                     
                      <th style={{minWidth: "180px"}}>Updated On</th>
                      <th style={{minWidth: "150px"}}>AWB No.</th>
                    
                   
                      <th style={{minWidth: "140px"}}>Booking Weight</th>
                      <th style={{minWidth: "140px"}}>Charged Amount</th>
                      <th style={{minWidth: "140px"}}>Updated Weight & Dimensions</th>
                      <th style={{minWidth: "140px"}}>Final Charged Amount</th>

                      <th style={{minWidth: "140px"}}>Difference Amount</th>
                      <th style={{minWidth: "120px"}}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.shipment_list.map((sub)=>(
                    <tr>
                      <td>
                        <p><small><Moment format="MMM DD YYYY">
                        {new Date(sub.discrepencies_date*1000)}
             </Moment> - <Moment format="hh:mm:ss a">
                         {new Date(sub.discrepencies_date*1000)}
             </Moment></small></p>
                      </td>
                      <td>
                        <strong><a href={`/customer/shipment_detail/${sub.shipment_id}`} style={{textDecoration:"none"}}>{sub.carrier_tracking_number}</a></strong><br/>
                        <p>{sub.carrier_name}</p>
                      </td>
                    
                   
                    
                      <td class="location_box_239">
                        <p>{sub.total_weight}KG</p>
                        {/* <p class="text-muted">+1.00KG</p> */}
                      </td>
                      <td>{sub.total_amount.toFixed(2)}</td>
                      <td>Dimensions - {sub.discrepencies_length?sub.discrepencies_length:sub.length} X {sub.discrepencies_width?sub.discrepencies_width:sub.width} X {sub.discrepencies_height?sub.discrepencies_height:sub.height}<br/>
                     Charged Weight - {sub.discrepencies_weight?sub.discrepencies_weight:sub.total_weight} KG</td>
                      <td>{sub.discrepencies_amount?sub.discrepencies_amount.toFixed(2):sub.total_amount.toFixed(2)}</td>

                      <td>
                      <p className={sub.discrepencies_amount ? 
  ((sub.total_amount - sub.discrepencies_amount) > 0 ? 'text-danger' : 'text-success') : 
  'text-danger'}>
  {sub.discrepencies_amount ? 
    ((sub.total_amount - sub.discrepencies_amount) > 0 ? 
      `+${(sub.total_amount - sub.discrepencies_amount).toFixed(2)}` : 
      (sub.total_amount - sub.discrepencies_amount).toFixed(2)) : 
    sub.total_amount.toFixed(2)}
</p>
                      </td>
                      <td>
                        <a href={`/customer/create_ticket?order_number=${encodeURIComponent(sub.carrier_tracking_number)}`} class="btns_staus text-decoration-none" data-bs-toggle="tooltip" title="Raise Ticket">
                          <i class="bx bx-support"></i> Raise Ticket
                        </a>
                      </td>
                    </tr>
                     ))} 
                  </tbody>
                </table>
            </div>
          </div>
        </div>

      </div>
    </section>
    <TablePagination
                component="div"
                 rowsPerPageOptions={[20,25,30,35,40,45,50]}
                 count={otherStates.total_count}
                 page={otherStates.page}
                 onPageChange={handlePageChange}
                 rowsPerPage={otherStates.rowsPerPage}
                 onRowsPerPageChange={handleChangeRowsPerPage}
               
                         />
   
    </div>
   
   
    
    </div>
    
  )
}

export default Weight_mismatch
