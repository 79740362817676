import React from 'react'

const Refund = () => {
  return (
    <div>
        <div className='container'>
        <h2 className='text-center mt-4'>Refund and Return Policy: </h2>
        <div className='card '>
        <div className='card-body'>
     
We strive to ensure customer satisfaction with every delivery. If you encounter any issues with your shipment, please notify us within 24 hours of receipt. Refunds will be issued for damaged or lost items, provided proper documentation is provided. Returns are accepted within 7 days of delivery for eligible products, subject to inspection and approval. Items must be in their original condition and packaging. Refunds will be processed within 14 days of approval. Please note that shipping costs are non-refundable. For further assistance, contact our customer support team.
            </div></div> 
        </div>
   
    </div>
  )
}

export default Refund
