import React from 'react'

const Privacy_policy = () => {
  return (
    <div>
      <div className='container'>
      <h2 className='text-center mt-4'>PRIVACY POLICY</h2>
        <div className='card'>
            <div className='card-body'>
            MYROCKETXPRESS.COM (“MYROCKETXPRESS.COM”) understands the importance of protecting the privacy of users of the MYROCKETXPRESS.COM website www.Myrocketxpress.COM. The information collected on the MYROCKETXPRESS.COM website is used to market and improve the services of MYROCKETXPRESS.COM and its subsidiaries’, to improve the content and services on the MYROCKETXPRESS.COM web site and make it easier to use and more appropriate to individual users and to contact its users with updates to the web site or for other marketing purposes. MYROCKETXPRESS.COM may use this information, with the users’ permission, for the above purposes.<br/>
MYROCKETXPRESS.COM never shares any information obtained on or from the MYROCKETXPRESS.COM website with any other organization, and will not do so in future, unless required by law. MYROCKETXPRESS.COM will not sell information obtained through the MYROCKETXPRESS.COM website to any party or parties.<br/>
Certain areas of the MYROCKETXPRESS.COM website, including but not limited to the sections on ‘Tracking’, ‘Service Guide’, ‘Franchisee’, ‘Corporate Solutions’, ‘Careers’ and ‘eSolutions’ require registration or a password for access. Information obtained from registered users of these areas may also be used for MYROCKETXPRESS.COM’s marketing purposes, and cookies may be used in those and other areas, as is described in this privacy policy. Information obtained in these areas may also be used in accordance with agreements governing access to and use of the specific areas, including, but not necessarily limited to the ‘Tracking’ section on the MYROCKETXPRESS.COM website. In addition, visitors to the MYROCKETXPRESS.COM website may choose to register for a MYROCKETXPRESS.COM website username and password to simplify access to some interactive features on the MYROCKETXPRESS.COM website. Log file data and other information gathered from registered users of the MYROCKETXPRESS.COM website are used to improve the customer experience of the users of the MYROCKETXPRESS.COM website.<br/>
The MYROCKETXPRESS.COM website login currently provides users with access to Tracking, Location Finder, Price and Time Finder, Create Shipment and Request Pickup. In the future, MYROCKETXPRESS.COM may add other features to those accessed through the MYROCKETXPRESS.COM website Login.<br/>
Please also see the MYROCKETXPRESS.COM ‘Conditions of Carriage’ for terms of carriage, limitations of liability, and disclaimers of warranty and general terms applicable to all delivery services and various information used and provided with the services of MYROCKETXPRESS.COM.<br/>
Certain shipment data will be provided to the authorities of the country of transit or destination for customs and tax clearance or for security screening, as required by the laws of such country. The information provided would usually include: shipper name and address, receiver name and address, description of the goods, number of pieces, weight and value of shipment.<br/>
FOR MORE INFORMATION ON THIS PRIVACY POLICY, PLEASE REFER TO THE DETAILED SECTION ON THE PRIVACY POLICY BELOW.<br/><br/>

<h4>Information collected about visitors to the MYROCKETXPRESS.COM web site:</h4>
The MYROCKETXPRESS.COM web server uses an extended log file format which captures: date and time of visit, referring address (location from which a visitor comes to the MYROCKETXPRESS.COM website), type of Internet browser, and visitor's IP address.<br/><br/>

<h4>Personal Information collected on the MYROCKETXPRESS.COM website:</h4>
Several areas of the MYROCKETXPRESS.COM website ask for personal information. They include the username registration, features of managing the user account of the users and registration pages for various online services offered by MYROCKETXPRESS.COM on the MYROCKETXPRESS.COM website. This list may be expanded without notice. In the event that it is expanded, additional information may be required. In these areas, the user’s name, address, email address, billing information, and business profile are requested. This information is collected to help us further develop MYROCKETXPRESS.COM’s services, to provide users access to various MYROCKETXPRESS.COM’s internet-based information and product services, and to bill users for those services if users elect to use them.<br/>
Manner of use of the user information collected on the MYROCKETXPRESS.COM website The information collected through the MYROCKETXPRESS.COM website site helps MYROCKETXPRESS.COM identify the type of web site content the customers value most. MYROCKETXPRESS.COM uses this information to market and improve the MYROCKETXPRESS.COM website and its services. MYROCKETXPRESS.COM also use this information to send you email notifications about updates to the MYROCKETXPRESS.COM website, and to contact the users by other means for marketing and other purposes. MYROCKETXPRESS.COM’s policy is to not give, sell or otherwise distribute the information collected through this site to third parties outside of MYROCKETXPRESS.COM and its subsidiaries (unless required by law); provided, however, in some cases MYROCKETXPRESS.COM may use suppliers to assist MYROCKETXPRESS.COM in collecting, using or otherwise processing for MYROCKETXPRESS.COM’s benefit the information obtained through the MYROCKETXPRESS.COM website. MYROCKETXPRESS.COM’s practice requires its suppliers to conduct such activities consistent with this policy and MYROCKETXPRESS.COM’s requirements of maintaining privacy of MYROCKETXPRESS.COM’s customers.<br/><br/>

<h4>Use of third party links on the MYROCKETXPRESS.COM website</h4>
Parts of the MYROCKETXPRESS.COM website consist of products and services hosted by subsidiaries and third-party businesses. When users leave the MYROCKETXPRESS.COM website to visit one of these sites, the only information transferred to the third-party is the fact that the user came from the MYROCKETXPRESS.COM website.<br/>
This practice allows the third-party to monitor their web site traffic, but does not provide them with any information about the users of the MYROCKETXPRESS.COM website. MYROCKETXPRESS.COM is not responsible or liable for the independent privacy policies of third-party sites. Users are advised to consult the privacy policies at those third party websites to determine how the user’s information may be used.<br/>
Cookies, their functionality on the MYROCKETXPRESS.COM website, how to remove them from the user’s hard drive:
A cookie is a series of data characters that, when programmed into a web site, is placed by the web server into the browser's application folder on the user’s computer. Once placed onto the user’s computer, the cookie will allow the web site to "recognize" you as a unique user of the website.<br/>
Cookies help MYROCKETXPRESS.COM with the capability to personalize information for certain segments of its customer base. They are also are used to allow MYROCKETXPRESS.COM the opportunity to associate individual customers with their information profiles. For example, through the use of cookies, the MYROCKETXPRESS.COM website username can "remember and auto fill" your user ID when users login their MYROCKETXPRESS.COM website Login username on the MYROCKETXPRESS.COM website from their computer.
Cookies can be removed from a user’s hard drive. Also, depending on what type of web browser and what browser version the user of the MYROCKETXPRESS.COM website is using, users may be able to change the properties on their cookie file so that cookies are not used or saved. Please check with the browser provider for more information on removing cookies.<br/>
SECURITY ALERT - SPAM MAILS<br/>
Fraudulent Email Alert:
You may be receiving reports of fraudulent emails with the subject lines "Shipping Conformation," "Verify Info," Promotion gifts and cheques" "Some important information is missing" and "Please fulfill the documents attached to verify your identity." The fraudulent email may have an attached file that may contain a virus or other malware.<br/>
If you receive a message matching this description below, do not open the email or click on the attachment. Delete the email immediately or forward it to the corporate IT team & Vigilance team.<br/>
MYROCKETXPRESS.COM does not request, via unsolicited mail or e-mail, payment or personal information in return for goods in transit or in MYROCKETXPRESS.COM custody. If the fraudulent e-mail resulted in financial loss you should contact your banking institution immediately through the appropriate channels. MYROCKETXPRESS.COM is not responsible for any charges or costs incurred as a result of unauthorized or fraudulent activity that abuses the MYROCKETXPRESS.COM name, service marks and logos.<br/><br/>

<h4>Common Warning Signs of Online Scams:</h4>
Unexpected requests for money in return for delivery of a package, gifts from a promotion company ,often with a sense of urgency.
Requests for personal and/or financial information.
Links to misspelled or slightly altered Web-site addresses (MYROCKETXPRESS.COM ,Com, Express Inc..etc.)
Spelling and grammatical errors or excessive use of capitalization and exclamation points. Claims that you have won a large sum of money in a lottery or settlement.
CONTACT DETAILS
The registered office of MYROCKETXPRESS.COM is :
MYROCKETXPRESS.COM
107, CR Das Rd, Sutragarh, Santipur, West Bengal , Santipur - 741404
The local postal address of MYROCKETXPRESS.COM’s offices can be found on the Customer Support page and the contact page.
Users can also write to MYROCKETXPRESS.COM for support at its email address : support@myrocketxpress.COM
MYROCKETXPRESS.COM reserves the right to amend the Privacy Policy at any time with or without notice. Please check back frequently in the event of changes.
The use of the MYROCKETXPRESS.COM website constitutes a user’s agreement to this Privacy Policy.
            </div>
        </div>
      </div>
    </div>
  )
}

export default Privacy_policy
