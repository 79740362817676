import React from 'react'

const Cancellation = () => {
  return (
    <div>
       <div className='container'>
        <h2 className='text-center mt-4'>Refund and Cancellation Policy: </h2>
        <div className='card '>
        <div className='card-body'>
     
        At My Rocket Xpress, we strive to provide excellent service to all our customers. We understand that there may be occasions where you need to cancel a shipment or request a refund. Please review our refund and cancellation policy outlined below:<br/><br/>

<h4>1. Cancellation Policy:</h4>
   - Customers can request a cancellation of their shipment before it is picked up by the courier service provider.<br/>
   - To cancel a shipment, customers must contact our customer support team via email or phone with their order details.<br/>
   - Once a shipment has been picked up by the courier service provider, cancellation requests cannot be accommodated.<br/>
   - If a cancellation request is made within a reasonable time frame and before pickup, the customer will be eligible for a full refund.<br/><br/>

<h4>2. Refund Policy:</h4>
   - Refunds will be provided in accordance with the cancellation policy and if the cancellation request is made within the stipulated time frame.<br/>
   - Refunds will be issued using the original payment method used by the customer.<br/>
   - It may take up to 7 business days for the refunded amount to reflect in the customer's account, depending on the payment method and banking institution.<br/><br/>

<h4>3. Exceptions to Refund Policy:</h4>
   - Refunds will not be provided for shipments that have already been picked up by the courier service provider.<br/>
   - Refunds will not be issued for shipments delayed due to reasons beyond our control, such as adverse weather conditions, traffic congestion, natural disasters, etc.<br/>
   - In the case of damaged or lost shipments, customers are advised to refer to our insurance policy for compensation.<br/><br/>

<h4>4. Customer Responsibilities:</h4>
   - Customers are responsible for providing accurate shipment details, including recipient address, contact information, and package dimensions.<br/>
   - Any discrepancies or errors in shipment details provided by the customer may affect the eligibility for refunds.<br/><br/>

<h4>5. Policy Revision:</h4>
   - My Rocket Xpress reserves the right to revise or amend this refund and cancellation policy at any time without prior notice. Any changes will be effective immediately upon posting on our website.<br/><br/>

By using our services, you agree to adhere to the terms and conditions outlined in this refund and cancellation policy. If you have any questions or concerns regarding our policy, please contact our customer support team for assistance.<br/>

My Rocket Xpress appreciates your understanding and cooperation.<br/>

Date of Last Revision: 20.03.2024
            </div></div> 
        </div>
    </div>
  )
}

export default Cancellation
