import React, {  useRef } from 'react'
import Header from './Header'
import Left_menu from './Left_menu'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Barcode from 'react-barcode';
import Modal from 'react-bootstrap/Modal';
// import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import download from 'downloadjs'
import Moment from 'react-moment';
import TablePagination from '@mui/material/TablePagination';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import html2canvas from 'html2canvas';
import BarcodeComponent from './BacodeComponent'
import { renderToString } from 'react-dom/server';
import createRoot from 'react-dom';
import { useNetworkState } from 'react-use';
import { BarcodeIcon, CornerUpLeft, DollarSign, FileDown, Package, PackageCheck, PackageIcon, PackageSearch, ShoppingBag, ShoppingBasket, Truck, TruckIcon } from 'lucide-react';
import Footer from '../Footer';
const Bulk_orders_log_detail = () => {
    const{log_id} = useParams() 
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====shipments====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
const[state,setState]= React.useState([])
console.log("state == " , state)
const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
const [shipmentstate, setshipStatus] = React.useState({ status: null });
const[labelstate,setlabel]= React.useState({shipment_list:[],isLoading:true})
const [selectedShipments, setSelectedShipments] = React.useState([]);
const[isloading , setisloading] = React.useState(false)

const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector('.home-section');
    if (homeSection) {
        homeSection.classList.toggle('sidebar-closed');
    }
};
    const shipment_list=(status)=>{
      console.log("status -- " , status)
        let dataToSend = {log_id : log_id
    };
     let url = config.apiUrl + '/shipment/bulk_shipment_log_view';
       axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            if (status !== null) {
               let data = res.data.output;
                 data = data.map(item => ({
                      ...item,
                      local_log: item.local_log.filter(log => log.status == status)
                  }));
               console.log("data === " , data)   
               setState(data);
              
           } else {
              setState(res.data.output);
          }
          const orderIds = res.data.output.flatMap(item => 
            item.local_log
                .filter(log => log.status === "1")
                .map(log => log.order_number)
        );
        setSelectedShipments(orderIds);
           })
          .catch((error) => {
           });    
   }

    React.useEffect(()=>{
        shipment_list(null)
    },[])
    React.useEffect(() => {
      shipment_list(shipmentstate.status);
  }, [shipmentstate.status]);

    const shipmentFilter = (status) => {
      console.log("action ", status);
      setshipStatus({ ...shipmentstate, status: status })
      // axios_get_api(status)
     
    }

    const label_shipments=()=>{
      setisloading(true)
      if(isOnline.online==true){
      let dataToSend = {customer_id : userData.customer_id,shipment_id:selectedShipments
  };
    let url = config.apiUrl + '/shipment/download_label_shipments';
     axios.post(url, dataToSend, { headers: config.headers })
        .then((res) => {
          console.log("responseJson => ", res);
          setlabel({shipment_list:res.data.output,total : res.data.total , company_info:res.data.company_info})
          setisloading(false)
         })
        .catch((error) => {
          setisloading(false)

        });    
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "Network Connection Error",
          
        })
        setisloading(false)

      }

  }

  React.useEffect(() => {
    // This effect will run every time labelstate is updated
    if (labelstate.shipment_list.length > 0) {
      onDownloadPDF();
    }
  }, [labelstate]); 
 
  const onDownloadPDF = async() => {
    const content = [];
    for (const sub of labelstate.shipment_list) {
        try {
          const pdfContent = htmlToPdfmake(`
          <table width="100%" border="0" style="border-collapse: collapse; border: 2px solid #000; font-size: 11px; line-height:1;">
          <tr class="" style="margin:0; padding:0px">
            <td colSpan='2' style='margin:0; padding:0px; font-weight:bold;'>
              Ship To:
            </td>
            <td align="right" style='margin:0; padding:0px;'>
            ${moment(new Date(sub.created * 1000)).format("MMM DD YYYY")}
            </td>
          </tr>
          <tr class="">
            <td colspan="3">
              <strong style="font-size:16px;">${sub.receiver[0].name}</strong></br>
               ${(sub.receiver[0].address)}</br>
                ${sub.receiver[0].city},${sub.receiver[0].state} ${sub.receiver[0].pincode} &nbsp;
                Mob: ${sub.receiver[0].mobile}
              
            </td>
          </tr>
          <tr class="">
            <td class="" style="width: 60%;">
             <strong style='font-size:10px;'>Shipment Mode</strong>:</br>
              ${sub.product_type_name?sub.product_type_name:"------"}</br>
              <strong style='font-size:10px;'>Payment Type</strong>:</br>
              ${sub.payment_mode}
            </td>
            <td colspan="2" style="width: 40%;">
            <strong style='font-size:16px;'>${sub.payment_mode=="cod"?`COD Amount: INR ${sub.cod_amount}`:"DO NOT COLLECT CASH"}</strong>
            </td>
          </tr>
          <tr style="border-bottom:0;">
            <td colspan="2" style="border-bottom:0; text-align:right; border-right:0;">
            <strong style='font-size:14px;'>${sub.carrier_name}</strong> 
            </td>
            <td colspan="0" style="border-bottom:0;  border-left:0;">
            <strong style='font-size:10px;'>ROUTE ----</strong> 
            </td> 
           </tr>
           <tr style="border-top:0;">
           <td colspan="3" style="margin: 0; padding: 0; line-height: 0; font-size: 0; border-top:0;">
           <img src="${sub.barcode_url_carrier}" style="height: 55px; width: 250px; margin-left:8px; display: block; padding: 0;">
           </img>
           </td>
          </tr>
          <tr class="border-bottom">
          <td>
          Product
      </td>
      <td>
          Qty
      </td>
      <td>
          Price
      </td>
  </tr> 
  ${sub.items.map((item)=>(`
    <tr class="border-bottom">
    <td>
    ${item.item_name}
    </td>
    <td colspan="">
    ${item.item_quantity}
    </td>
    <td colspan="">
        ${item.item_value}
    </td>
</tr>`
  ))}
  <tr class="border-bottom">
    <td>
      Total
    </td>
    <td colspan="">
      
    </td>
    <td colspan="">
    ${sub.totalValue}
    </td>
  </tr>
  <tr>
  <td colspan="3" class="" style="margin: 0; padding: 0; line-height: 0; font-size: 0;">
  <img src="${sub.barcode_url}" style="height: 55px; width: 250px; margin-left:8px; display: block; padding: 0;">
</td>
  </tr>
  <tr style="margin: 0px;">
    <td colspan="3" class="" style="margin: 0px;">
    <strong style='font-weight:bold;'> Return Address</strong>: ${(sub.return[0].address)}, ${sub.return[0].city}-${sub.return[0].pincode}, ${sub.return[0].state}, ${sub.return[0].country}
    </td>
  </tr>
</table>

           `);
  
  
          content.push(pdfContent);
          content.push({ text: '', pageBreak: 'after' });
          setlabel({shipment_list:[]})
        } catch (error) {
          console.error(`Error converting HTML to pdfmake format for shipment :`, error);
        }
    };
    content.pop();
   const pdfDoc = {
      content,
      pageSize: { width: 4 * 72, height: 6 * 72 },
      pageOrientation: 'portrait',
    };
    pdfMake.createPdf(pdfDoc).download('labels.pdf');
  };
  return (
    <div >
   <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
         
          <Header/>
        
{state.map((sub)=>(
        <section class="mb-3 pt-4">
          <div class="">
            <div class="row mb-3">
              <div class="col-12 col-md-3">
                <h3><a href={`/customer/bulk_orders_log/${sub.log_id}`}><i class="fa fa-chevron-left" aria-hidden="true"></i></a> Bulk Orders Log</h3>
                <p>ID: {sub.log_id}  <small class="text-muted"><i class="fa fa-clock-o" aria-hidden="true"></i> {sub.time} {sub.date}</small></p>
              </div>
              
              <div class="col-12 col-md text-end">
                <h3>{sub.local_uploaded_records}<small class="text-muted">/{sub.total_records}</small></h3>
                <p><span class="badge rounded-pill bg-success fw-400">{sub.status==0?"Running":"Completed"}</span></p>
              </div>

            </div> 
          </div>
        </section>
))}

<section>
        <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class={(shipmentstate.status == null) ? 'nav-link active' : 'nav-link'}  onClick={() => shipmentFilter(null)} style={{cursor:"pointer"}} >All</a>
            </li>
            <li class="nav-item">
              <a class={(shipmentstate.status == 1) ? 'nav-link active' : 'nav-link'} onClick={() => shipmentFilter(1)} style={{cursor:"pointer"}}>Completed</a>
            </li>
            <li class="nav-item">
              <a class={(shipmentstate.status == 2) ? 'nav-link active' : 'nav-link'} onClick={() => shipmentFilter(2)} style={{cursor:"pointer"}}>Error</a>
            </li>
            {/* {shipmentstate.status==null || shipmentstate.status == 1?
            <>
             <li class="nav-item">
            {isloading? <img src="/img/spinner.gif" class="img-fluid" style={{maxHeight: "40px" , marginLeft: '100px'}} />:
           <a className='nav-link bg-primary ' style={{ marginLeft: '300px' , color:"white" , cursor:"pointer"}} onClick={()=>label_shipments()}><FileDown/>Download Labels</a>}
           </li>
            <li class="nav-item">
           
           <a className='nav-link bg-primary ' style={{ marginLeft: '500px' , color:"white" , cursor:"pointer"}} onClick={()=>label_shipments()}><FileDown/>Download Labels</a>
           </li>
           </>
          
           
           :""} */}

           <li className='nav-item' style={{ marginLeft: '700px'}}>
           {isloading? <img src="/img/spinner.gif" class="img-fluid" style={{maxHeight: "40px" }} />:
           <a className='nav-link bg-primary ' style={{ color:"white" , cursor:"pointer"}} onClick={()=>label_shipments()}><FileDown/> Download Labels</a>}
           </li>

           <li className='nav-item' style={{ marginLeft: '10px'}}>
            <a className='nav-link bg-primary' href={`/print_label/${selectedShipments.join(',')}`} style={{ color:"white" , cursor:"pointer"}}><BarcodeIcon/> Print Labels</a>
           </li>
          
          </ul>
        
         
      </section>
        <section class="mb-3">
          <div class="">
            <div class="table-responsive">
                <table class="table table_box">
                    <thead  class="">
                        <tr class="">
                        <th>Order Number</th>
                        <th>Status</th>
                        <th>Error Log</th>
                    </tr>
                    </thead>
                    <tbody>
                        {state.map((sub)=>(
                            sub.local_log.length>0?
                            sub.local_log.map((item)=>(
                        <tr class="brd">
                            <td>
                                <h5><a href="#"></a>{item.order_number}</h5>
                            </td>
                            <td>
                               {item.status==1? <h2><span class="badge rounded-pill bg-success fw-400">Completed</span></h2>:item.status==0? <h2><span class="badge rounded-pill bg-info fw-400">Running</span></h2>:<h2><span class="badge rounded-pill bg-danger fw-400">Error</span></h2>}
                            </td>
                            <td>
                                <h5>{item.reason}</h5>
                            </td>
                        </tr>
                        )):""
                        ))}
                        
                    </tbody>
                </table>
            </div>     
          </div>
        </section>
        
  

    </div>
    {/* <?php include 'footer.php';?> */}
    {/* <Footer/> */}
    </div>
  )
}

export default Bulk_orders_log_detail
