import React from 'react'

const Contact = () => {
  return (
    <div>
      <div className='container'>
        <h2 className='text-center mt-4'>
            CONTACT US
        </h2>
        <div className='card'>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-3'>
<h5> Call Us 24/7</h5><br/>
+91 9339151330

                    </div>
                    <div className='col-3'>
                    <h5> Mail Us 24/7</h5><br/>
                    support@myrocketxpress.in
                    </div>
                    <div className='col-3'>
                    <h5> Opening Hours</h5><br/>
                    Mon - Sat : 10:00am - 07:00pm
                    </div>
                    <div className='col-3'>
                    <h5> Address</h5><br/>
                    107 CR das ROAD, Sutragarh,Santipur 
                    </div>

                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
