import React, { useEffect, useState } from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import Create_order_nav from './Create_order_nav'
import axios from 'axios'
import queryString from 'query-string';
import { useNavigate,useLocation, useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';


import Swal from 'sweetalert2'

import ReactLoading from 'react-loading';
import config from "../../config"
import { useNetworkState } from 'react-use';

const Channel_integrate = () => {
    let navigate = useNavigate();
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
 const {channel_id } = useParams();
    const[state,setState] = useState({name:"" , url:"" , key:"" , password:""})
    const[tempstate,settempState] = useState({name:"" , url:"" , key:"" , password:""})

    // console.log("state === " , state)
    const[isLoading , setisLoading] = useState(false)
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    const handleChange = (e)=>{
        // let names = e.target.name
        let values = e.target.value
         setState({...state , [e.target.name]:values})
    }
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
      })

    const addStore = ()=>{
     setisLoading(true)
        let url = config.apiUrl + '/shipment/store_add_test_api';
        let sendData = {channel_id:channel_id , store_name:state.name , store_url:state.url , store_api_key : state.key , store_password:  state.password };
        // console.log("bb", sendData);
        axios.post(url, sendData, { headers: config.headers }).then((res) => {
            if(res.data.status==true){    
                
                Toast.fire({
                    background:"rgb(25, 135, 84)",
                    type: 'unsuccess',
                    title: "Store Added Successfully",
                    color:"white"
                  });
                  setisLoading(false)
    navigate('/customer/channel_list')
              
            }
            else{
                Toast.fire({
                    background:"rgb(25, 135, 84)",
                    type: 'unsuccess',
                    title: res.data.message,
                    color:"white"
                  });
                  setisLoading(false)
            }
    
        }).catch((e) => {
            Toast.fire({
                background:"rgb(25, 135, 84)",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
    
        });
    }

    const testWoocommerce = ()=>{
        setisLoading(true)
        const randomUserId = Math.floor(10000 + Math.random() * 90000);

        // Append user_id to the current browser URL
        const newUrl = `${window.location.href}?user_id=Rocketxpress_${randomUserId}`;
        
        // Replace the current browser URL with the new one
        window.history.replaceState({}, document.title, newUrl);
           let url = config.apiUrl + '/user/generate_woocommerce_keys';
           let sendData = {channel_id:channel_id , store_url:state.url ,  redirectUrl: window.location.origin + `/customer/channel_integrate/${channel_id}`, user_id:`Rocketxpress_${randomUserId}` };
           // console.log("bb", sendData);
           axios.post(url, sendData, { headers: config.headers }).then((res) => {
               if(res.data.status==true){    
                   setisLoading(false)
                   const authUrl = res.data.url;
                    window.location.href = authUrl;
                  }
               else{
                   Toast.fire({
                       background:"rgb(25, 135, 84)",
                       type: 'unsuccess',
                       title: res.data.message,
                       color:"white"
                     });
                     setisLoading(false)
               }
       
           }).catch((e) => {
               Toast.fire({
                   background:"rgb(25, 135, 84)",
                   type: 'unsuccess',
                   title: "Something Went Wrong",
                   color:"white"
                 });
       
           });
       }

       const getwoocommercegeneratedkeys = ()=>{
        let uid =""
        const currentUrl = window.location.href;
        console.log("currentUrl == " , currentUrl)
        const urluser = new URL(currentUrl);
        console.log("urluser == " , urluser)

        // Get the value of the 'user_id' parameter
        const userId = urluser.searchParams.get('user_id');
        console.log("userId == " , userId)
        if(userId!=null && userId!=undefined){
            uid = userId
        }
           let url = config.apiUrl + '/user/get_woocommerce_keys';
           let sendData = {channel_id:channel_id , user_id:uid};
           // console.log("bb", sendData);
           axios.post(url, sendData, { headers: config.headers }).then((res) => {
               if(res.data.status==true){    
                 
                setState({...state , url:res.data.output.store_url , key:res.data.output.store_api_key , password:res.data.output.store_password})
                settempState({...tempstate ,url:res.data.output.store_url , key:res.data.output.store_api_key , password:res.data.output.store_password})
                  }
               else{
                 
               }
       
           }).catch((e) => {
              
       
           });
       }

       React.useEffect(()=>{
        getwoocommercegeneratedkeys()
       },[])

       const saveWoocommerce = ()=>{
        setisLoading(true)
        let uid =""
        const currentUrl = window.location.href;
        console.log("currentUrl == " , currentUrl)
        const urluser = new URL(currentUrl);
        console.log("urluser == " , urluser)

        // Get the value of the 'user_id' parameter
        const userId = urluser.searchParams.get('user_id');
        console.log("userId 00== " , userId)
        if(userId!=null && userId!=undefined){
            uid = userId
        }
           let url = config.apiUrl + '/shipment/save_wooCoommerce_orders';
           let sendData = {channel_id:channel_id , store_name:state.name , store_url:state.url , store_api_key : state.key , store_password:  state.password  , unique_user:uid};
           // console.log("bb", sendData);
           axios.post(url, sendData, { headers: config.headers }).then((res) => {
               if(res.data.status==true){    
                  
                   Toast.fire({
                       background:"rgb(25, 135, 84)",
                       type: 'unsuccess',
                       title: "Store Added Successfully",
                       color:"white"
                     });
                     setisLoading(false)
       navigate('/customer/channel_list')
                   
               }
               else{
                   Toast.fire({
                       background:"rgb(25, 135, 84)",
                       type: 'unsuccess',
                       title: res.data.message,
                       color:"white"
                     });
                     setisLoading(false)
               }
       
           }).catch((e) => {
               Toast.fire({
                   background:"rgb(25, 135, 84)",
                   type: 'unsuccess',
                   title: "Something Went Wrong",
                   color:"white"
                 });
       
           });
       }
  return (
    <div >
       
       <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
         
          <Header/>
        
         {channel_id=="1567898605"?
        <section class="mb-3  shopify">
          <div class="">
           <h2 >Integrate Shopify</h2> 
            
                

                <div class="row  mb-3">
                    <div class="col-12 col-md-5">
                        <figure>
                            <img src="../../../img/shopify.png" class="img-fluid" style={{height:"100px"}} />
                        </figure>

                        <h5>Shopify</h5>
                    <p>Use the following steps to Integrate Shopify </p>
                    <ol>
                        <li>Login into Shopify Admin panel</li>
                        <li>Go to Apps</li>
                        <li>Scroll down to find a Manage Private Apps use.</li>
                        <li>Here, click on crate new private app</li>
                        <li>Go ahead and give your Private app a appropiate name. For eg. Rocketxpress</li>
                        <li>Next, we need some admin access for some speicific apis 
                            <ul>
                                <li><strong>Read Access:</strong> Fullfillment Service & Inventory</li>
                                <li><strong>Read & Write Access:</strong> Products, Products Listing, Assigned Fullfillment Orders, Customers, Orders, Merchant Managment Fillfilled Ordres, Order Editing, Store Content, Third Party fullfillment Orders</li>
                            </ul>
                        </li>
                        <li>Save your changes to get API credentials
                            <ul>
                                <li><strong>API KEY, Password </strong></li>
                                <li><strong>Note:</strong> For store url use: <br/>
                                yourstorename.myshopify.com</li>
                            </ul>
                        </li>
                        <li>Paste the same credentials here to integrate the shopfiy channel</li>
                    </ol>
                    </div>
                    <div class="col-12 col-md">
                        <div class="card border-0 shadow-sm mb-3">
                          <div class="card-body">
                            <h6 class="pb-4">Setting</h6>
                            <div class="row">
                           
                                <div class="col-12 col-md-12 mb-3">
                                    <label for="email" class="form-label">Store Name:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Name" name="name" onChange={(e)=>handleChange(e)} />
                                </div>
                               
                                 
                                <div class="col-12 col-md-12 mb-3">
                                    <label for="email" class="form-label">Store URL:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter URL Link" name="url" onChange={(e)=>handleChange(e)}  />
                                </div>
                                
                                <div class="col-12 col-md-6 mb-3">
                             <label for="email" class="form-label">API KEY:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter API KEY" name="key" onChange={(e)=>handleChange(e)} />
                                </div>
                               

                                 
                                <div class="col-12 col-md-6 mb-3">
                            <label for="email" class="form-label">API Password:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Password" name="password" onChange={(e)=>handleChange(e)}  />
                                </div>
                                
                                {/* <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">Shared Secret:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Shared Secret" name="email" />
                                </div> */}
                            </div>

                            <div class="py-4">
                            {isLoading? 
      <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} /> :
                            <a onClick={()=>addStore()} class="btn btn-primary">Save & Test Integration</a>
                            }
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
          </div>
        </section>
:
        <section class="mb-3 mt-3 shopify">
          <div class="">
           <h3 class="py-3">Integrate WooCommerce</h3> 
            
                

                <div class="row mt-4 mb-3">
                    <div class="col-12 col-md-5">
                        <figure>
                            <img src="https://cdn.myrocketxpress.com/channel_images/wooCommerce.png" class="img-fluid" style={{height:"100px"}} />
                        </figure>

                        <h5>WooCommerce</h5>
                    {/* <p>Use the following steps to Integrate Shopify </p> */}
                    {/* <ol>
                        <li>Login into Shopify Admin panel</li>
                        <li>Go to Apps</li>
                        <li>Scroll down to find a Manage Private Apps use.</li>
                        <li>Here, click on crate new private app</li>
                        <li>Go ahead and give your Private app a appropiate name. For eg. Shiport</li>
                        <li>Next, we need some admin access for some speicific apis 
                            <ul>
                                <li><strong>Read Access:</strong> Fullfillment Service & Inventory</li>
                                <li><strong>Read & Write Access:</strong> Products, Products Listing, Assigned Fullfillment Orders, Customers, Orders, Merchant Managment Fillfilled Ordres, Order Editing, Store Content, Third Party fullfillment Orders</li>
                            </ul>
                        </li>
                        <li>Save your changes to get API credentials
                            <ul>
                                <li><strong>API KEY, Password </strong></li>
                                <li><strong>Note:</strong> For store url use: <br/>
                                yourstorename.myshopify.com</li>
                            </ul>
                        </li>
                        <li>Paste the same credentials here to integrate the shopfiy channel</li>
                    </ol> */}
                    </div>
                    <div class="col-12 col-md">
                        <div class="card border-0 shadow-sm mb-3">
                          <div class="card-body">
                            <h6 class="pb-4">Setting</h6>
                            <div class="row">
{/*                            
                                <div class="col-12 col-md-12 mb-3">
                                    <label for="email" class="form-label">Store Name:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Name" name="name" onChange={(e)=>handleChange(e)} />
                                </div>
                                */}
                                 
                                <div class="col-12 col-md-12 mb-3">
                                    <label for="email" class="form-label">Store URL:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter URL Link" name="url" onChange={(e)=>handleChange(e)} value={state.url} />
                                </div>
                                {tempstate.url!=""?
                                <div class="col-12 col-md-6 mb-3">
                            <label for="email" class="form-label">Consumer Key:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter API KEY" name="key" onChange={(e)=>handleChange(e)}  value={state.key}/>
                                </div>
                                :""}
                               

                               {tempstate.url!=""?
                                <div class="col-12 col-md-6 mb-3">
                           <label for="email" class="form-label">Consumer Secret:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Password" name="password" onChange={(e)=>handleChange(e)}  value={state.password}/>
                                </div>
                                :""}
                                
                                {/* <div class="col-12 col-md-6 mb-3">
                                    <label for="email" class="form-label">Shared Secret:</label>
                                    <input type="text" class="form-control" id="" placeholder="Enter Shared Secret" name="email" />
                                </div> */}
                            </div>

                            <div class="py-4">
                                {tempstate.url==""?
                            isLoading? 
      <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} /> :
                            <a onClick={()=>testWoocommerce()} class="btn btn-primary">Connect To WooCommerce</a>
                            
                        :   isLoading? 
                            <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} /> :
                                                  <a onClick={()=>saveWoocommerce()} class="btn btn-primary">Test WooCommerce</a>
                                                  }
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
          </div>
        </section>
}
   

    </div>
    {/* <?php include 'footer.php';?> */}
    </div>
  )
}

export default Channel_integrate
